import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* 27 days 125 USDT with capital 2500 USDT */
    }
    <p>{`March 16, I shorted ~0.19 BTC at 40300 USDT (worth 8000 USDT), then the price goes straight up to 48000 USDT.
By that time I could lose up to 1500 USDT but I decided to hold and wait.`}</p>
    <p>{`April 12, the price drops and I close the position at 39666 USDT which generates a profit of 125 USDT.
It took me 27 days to close this position with the risk of been liquidated. The final APR is around 67.5%.`}</p>
    <p>{`I tried a simple grid trading estimation; it is way safer and simpler to achieve the same APR.
I should be writing an automated bot to do the trading for me instead of trading manually.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      